var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBL0000750", topClass: "topcolor-orange" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                label: "LBL0000599",
                                icon: "check",
                                color: "purple",
                              },
                              on: { btnClicked: _vm.saveApprRequest },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "LBL0000752",
                              name: "approvalTypeName",
                            },
                            model: {
                              value: _vm.apprtype.approvalTypeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.apprtype, "approvalTypeName", $$v)
                              },
                              expression: "apprtype.approvalTypeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              required: true,
                              label: "LBL0000753",
                              name: "approvalRequestName",
                            },
                            model: {
                              value: _vm.reqdata.approvalRequestName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.reqdata,
                                  "approvalRequestName",
                                  $$v
                                )
                              },
                              expression: "reqdata.approvalRequestName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      staticStyle: { "margin-top": "-15px !important" },
                    },
                    [
                      _c("c-table", {
                        ref: "apprline2",
                        attrs: {
                          title: "LBL0000754",
                          tableId: "apprline2",
                          topBorderClass: "topcolor-orange",
                          columnSetting: false,
                          isFullScreen: false,
                          hideBottom: true,
                          usePaging: false,
                          filtering: false,
                          isExcelDown: false,
                          gridHeight: "220px",
                          columns: _vm.gridline2.columns,
                          data: _vm.gridline2.data,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      staticStyle: { "margin-top": "-15px !important" },
                    },
                    [
                      _c(
                        "c-table",
                        {
                          ref: "apprline",
                          attrs: {
                            title: "LBL0000755",
                            tableId: "apprline",
                            topBorderClass: "topcolor-orange",
                            columnSetting: false,
                            isFullScreen: false,
                            hideBottom: true,
                            usePaging: false,
                            filtering: false,
                            isExcelDown: false,
                            gridHeight: "220px",
                            columns: _vm.gridline.columns,
                            data: _vm.gridline.data,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "customArea",
                              fn: function ({ props, col }) {
                                return [
                                  col.name === "approvalKindName"
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-weight-bold",
                                            class: _vm.getLineKindColor(
                                              props.row.approvalKindCd
                                            ),
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(props.row.approvalKindName)
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  col.name === "approvalStatusName"
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            class: _vm.getLineKindColor2(
                                              props.row.approvalStatusName
                                            ),
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.row.approvalStatusName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "template",
                            { slot: "table-button" },
                            [
                              _c(
                                "q-btn-group",
                                { attrs: { outline: "" } },
                                [
                                  _c("c-btn", {
                                    attrs: {
                                      label: "LBL0000756",
                                      icon: "change_circle",
                                      color: "orange",
                                    },
                                    on: { btnClicked: _vm.changeApprLine },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm displayBlockCard",
                attrs: { title: "LBL0000757", topClass: "topcolor-orange" },
              },
              [
                _c(
                  "template",
                  { slot: "card-detail" },
                  [
                    _c(_vm.component, {
                      tag: "component",
                      attrs: {
                        popupParam: _vm.popupParam.approvalParamValue,
                        contentHeight: _vm.contentHeight,
                      },
                      on: {
                        "update:popupParam": function ($event) {
                          return _vm.$set(
                            _vm.popupParam,
                            "approvalParamValue",
                            $event
                          )
                        },
                        "update:popup-param": function ($event) {
                          return _vm.$set(
                            _vm.popupParam,
                            "approvalParamValue",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.dialogShow,
            callback: function ($$v) {
              _vm.dialogShow = $$v
            },
            expression: "dialogShow",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "350px" } },
            [
              _c(
                "q-form",
                { ref: "editForm" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "LBL0000758" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.editable,
                                    expression: "editable",
                                  },
                                ],
                                attrs: { label: "LBL0000778", icon: "save" },
                                on: { btnClicked: _vm.saveDialog },
                              }),
                              _c("c-btn", {
                                attrs: {
                                  label: "LBL0000759",
                                  color: "red",
                                  showLoading: false,
                                },
                                on: { btnClicked: _vm.eraseSignature },
                              }),
                              _c("c-btn", {
                                attrs: { label: "LBLCANCEL", icon: "cancel" },
                                on: { btnClicked: _vm.closeDialog },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-sm-12" },
                            [
                              _c("VueSignaturePad", {
                                ref: "signaturePad",
                                attrs: { width: "300px", height: "300px" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }